<template>
    <v-card v-show="mostrar(true)" raised :height="height" :width="width" class="m-card">
        <v-btn fab absolute top right @click="mostrar(false)">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <router-view/>
        <slot></slot>
    </v-card>
</template>

<script>
    export default {
        name: "ConteudoLateral",
        props: {
            show: Boolean,
            parentRoute: {
                String,
                default:''
            },
            width: {
                type: String,
                default:'auto',
            },
            height:{
                type: String,
                default:'auto',
            },
        },
        data: () =>{
            return{
                //show: false
            }
        },
        methods:{
            mostrar(exibir){
                if(exibir){
                    if (this.parentRoute){
                        return this.$route.fullPath !== this.parentRoute;
                    }
                    else return this.show;
                }
                else{
                    if(this.parentRoute) this.$router.push(this.parentRoute);
                    else this.$emit('mostrar', false);
                }

            }
        }
    }
</script>

<style scoped>
    .v-card{
        z-index: 9999;
        position: absolute;
        left: 0;
    }
</style>