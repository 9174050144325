import { loadModules, loadCss } from "esri-loader";
import bus from '../bus'
const version = '4.13'
const options = {version}

//let map, view, helperLayer, basemapToggle, basemapGallery;
class Arcgis {
    
    view = null
    map = null
    fixedPosition = false
    lat = null
    log = null

    constructor(lat=null, log=null) {
        if(lat != null && log != null) {
            this.lat = lat
            this.log = log
            this.fixedPosition = true
        }
        this.view;
        this.permissao = JSON.parse(sessionStorage.user).user.permissao
        this.adm = this.permissao.search(/super|admin|sysadmin/gi) !== -1
    }

    loadGraphicArray(layer, name){
        let graphics = [];
        let arc = layer.map(el => ({
            geometry: new this.Point({
                longitude: el.geometry.coordinates[0],
                latitude: el.geometry.coordinates[1],
            }),
            attributes: el.properties
        }))
        // arc.filter((el) => {
        //     if(this.permissao.search(/super/gi) !== -1) graphics.push(el)
        //     else if(el.attributes.setor == JSON.parse(sessionStorage.user).user.setor) graphics.push(el)
        // })
        this.createLayer(arc, name)
    }

    // ADICIONAR OS HELPERS AO MAPA
    // ALÉM DE CONFIGURAR O POP-UP AO CLICAR NO HELPER
    createLayer(graphics, name) {
        const layer =  new this.FeatureLayer({
            title: name,
            source: graphics,
            objectIdField: "OBJECTID",
            fields: [
                { name: "OBJECTID", type: "oid" },
                { name: "nome", type: "string" },
                { name: "ip_helper", type: "string" },
                { name: "setor", type: "string" }
            ],
            popupTemplate: {
                title: "{nome}",
                content:"<b>IP:</b> {ip_helper}<br>" +
                    "<b>Local:</b> {setor}"
            },
            renderer: {
                type: "simple",
                symbol: {
                    type: "picture-marker",
                    url:"favicon.ico",
                    width: 28,
                    height: 28,
                }
            }
        })
        this.addToView(layer)
    }

    addToView(layer) {
        this.view.map.add(layer);
        layer.when(() => {
            layer.queryExtent().then(response => {
                //this.view.zoom = 12
                if(response.extent.height < 100) this.view.extent = response.extent
                if(this.fixedPosition) {
                    this.view.center = [this.lat, this.log]
                } else {
                    this.view.center = [response.extent.center.x, response.extent.center.y]
                }
            })
        })
    }

    loader(){
        loadCss(version)
        loadModules([
            'esri/Map',
            'esri/views/MapView',
            "esri/layers/FeatureLayer",
            "esri/widgets/Locate",
            "esri/widgets/Expand",
            "esri/widgets/BasemapToggle",
            "esri/widgets/BasemapGallery",
            "esri/Graphic",
            "esri/widgets/LayerList",
            "esri/symbols/PictureFillSymbol",
            "esri/geometry/Point",
            "esri/widgets/Sketch/SketchViewModel",
            "esri/layers/GraphicsLayer",
            "esri/geometry/Polygon",
            "esri/widgets/Expand"
        ], options).then(([Map, MapView, FeatureLayer,
                              Locate, Expand,
                              BasemapToggle, BasemapGallery,
                              Graphic, LayerList,
                              PictureFillSymbol, Point,
                              SketchViewModel, GraphicsLayer]) => {

            this.MapView = MapView,
                this.FeatureLayer = FeatureLayer
            this.Locate = Locate
            this.Expand = Expand
            this.BasemapToggle = BasemapToggle
            this.BasemapGallery = BasemapGallery
            this.Graphic = Graphic
            this.LayerList = LayerList
            this.Point = Point

            let sketchViewModel,
                boundaryPolygon,
                validSymbol,
                invalidSymbol,
                buffers;

            let intersects = false,
                contains = true;

            const map = new Map({
                basemap: "dark-gray-vector"
            });

            this.map = map;

            const view = new MapView({
                container: "viewDiv",
                map: map,
                center: [-46.67,-23.55],
                zoom: 13
            });

            this.view = view;

            setUpGraphicClickHandler();

            view.when(function() {

                sketchViewModel = new SketchViewModel({
                    view: view,
                    layer: GraphicsLayer,
                    updateOnGraphicClick: false,
                    defautlUpdateOptions: {
                        toggleToolOnClick: false
                    }
                });

                sketchViewModel.on(["update", "undo", "redo"], onGraphicUpdate);
            });

            function onGraphicUpdate(event) {
                const graphic = event.graphics[0];
                intersects = geometryEngine.intersects(buffers, graphic.geometry);
                contains = geometryEngine.contains(boundaryPolygon, graphic.geometry);

                graphic.symbol =
                    intersects || !contains ? invalidSymbol : validSymbol;

                if (
                    event.toolEventInfo &&
                    (event.toolEventInfo.type === "move-stop" ||
                        event.toolEventInfo.type === "reshape-stop")
                ) {
                    if (contains && !intersects) {
                        sketchViewModel.complete();
                    }
                } else if (event.state === "complete") {

                    if(!contains || intersects) {
                        sketchViewModel.update({ tool: "reshape", graphics: [graphic] });
                    }
                }
            }

            function setUpGraphicClickHandler() {
                view.on("click", function(event) {
                    if(sketchViewModel.state === "active") {
                        return;
                    }
                    view.hitTest(event).then(function(response) {
                        var results = response.results;
                        results.forEach(function(result) {
                            if (
                                result.graphic.layer === sketchViewModel.layer &&
                                result.graphic.attributes &&
                                result.graphic.attributes.newDevelopment
                            ) {
                                sketchViewModel.update([result.graphic], { tool: "reshape"});
                            }
                        });
                    });
                });
            }

            this.setEvents();

        })
    }

    setEvents(){
        this.view.when(() => {
            bus.$emit('carregouMap', true)
            this.carregarWidgets()
        })

        // EVENTS
        this.view.on('click', (event) => {
            //console.log('configurou click')
            this.view.hitTest(event).then((response) => {
                if(event.button == 2 || event.pointerType == 'touch') bus.$emit("rightClickMap", response);
            });
        });
    }

    clearEvents(){ }

    carregarWidgets(){

        // Add the search widget to the top left corner of the view
        this.view.ui.add(this.searchWidget, "top-right");

        this.layerList = new this.Expand({
            expandIconClass: 'esri-icon-hollow-eye',
            expandTooltip: "Lista de Camadas",
            expanded: false,
            autoCollapse: true,
            content: new this.LayerList({ view:this.view }),
            view:this.view
        })


        /*
            LOCATE
        */
        this.locateBtn = new this.Locate({ view:this.view });

        this.view.ui.add(this.locateBtn, "top-left");

        /*
            BASEMAP
        */
        this.basemapToggle = new this.BasemapToggle({
            view:this.view,
            nextBasemap: "topo-vector"
        });

        this.basemapGallery = new this.BasemapGallery({
            view:this.view,
            toggle: true,
            source: {
                portal: {
                    url: "https://www.arcgis.com",
                    useVectorBasemaps: true  // Load vector tile basemaps
                }
            }
        });


        this.basemapSelector = new this.Expand({
            expandIconClass: 'esri-icon-basemap',
            expandTooltip: "Basemap Gallery",
            expanded: false,
            autoCollapse: true,
            view:this.view,
            content: this.basemapGallery
        });
        this.view.ui.add([this.basemapToggle, this.basemapSelector, this.layerList], 'bottom-left');
    }

    removeListeners(){
        ////console.log("remove arcgis listeners");
        ////console.log(bus.$listeners);
    }
}

export default Arcgis
