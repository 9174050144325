<template>
    <v-menu
            :value="showMenu"
            :position-x="x+10"
            :position-y="y+80"
            transition="slide-y-transition"
            absolute
            right
    >
        <v-list dense>
            <v-subheader v-text="title"></v-subheader>
            <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    @click="$emit('select', item)"
            >
                <v-list-item-icon v-if="typeof item.sub === 'undefined'">
                    <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
                    <v-icon v-else>mdi-chevron-right</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title v-text="item.title" v-if="typeof item.sub === 'undefined'"></v-list-item-title>
                    <v-menu open-on-hover right offset-x v-else>
                        <template v-slot:activator="{ on }">
                            <v-list-item v-on="on">
                            <v-list-item-title v-text="item.title"></v-list-item-title>
                            <v-list-item-icon>
                                <v-icon  v-if="typeof item.sub != 'undefined'">mdi-chevron-right</v-icon>
                            </v-list-item-icon>
                            </v-list-item>
                        </template>
                        <v-list dense>
                            <v-list-item
                                v-for="(sub, index) in item.sub"
                                :key="index"
                                @click="$emit('select', sub)"
                                >
                                <v-list-item-title v-text="sub.title"></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </v-list-item-content>
            </v-list-item>

        </v-list>
    </v-menu>
</template>

<script>
    //import bus from '../bus'

    export default {
        name: "ContextMenu",
        props:{
            showMenu: Boolean,
            title: String,
            items: Array,
            x: Number,
            y: Number,
        },
    }
</script>
