<template>
    <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" style="height: 100%">
      <v-progress-circular v-show="carregando" :size="100" :width="10" color="grey" indeterminate></v-progress-circular>
        <v-skeleton-loader id="viewDiv" @contextmenu.prevent="" >
            <!--<context-menu :title="titulo" :show-menu="showMenu" :items="items" :x="x" :y="y" @select="acaoMenu" >
            </context-menu>-->
            <conteudo-lateral class="pr-8 pb-8" :width="lateralWidth" parent-route="/map">
            </conteudo-lateral>
        </v-skeleton-loader>
    </v-sheet>
</template>
<script>
    import bus from '../bus'
    import ContextMenu from "../components/ContextMenu";
    import ConteudoLateral from "../components/ConteudoLateral";
    //import * as Arcgis from './arcgis'
    import Arcgis from './ClassArcgis'
    let arcgis

    export default {
        inject: ['theme'],
        name: "Map",
        components:{
            //ContextMenu,
            ConteudoLateral,
        },
        data: () => ({
            carregando: true,
            listaHelpers: [],
            permissao:'',
            showMenu: false,
            mapArgs: '',
            lateralWidth:"50%",
            x: 0,
            y: 0,
            latitude:0,
            longitude:0,
            titulo: "Helper",
            items: [{}],
            menuMapa: [
                { title: 'Clique em Totem para interagir', icon: 'mdi-help-circle', action:'RUA'},
            ],
            menuAudio:[],
            menuAgente:[
                { title: 'Abrir Canal Usuário', icon: 'mdi-phone-in-talk', action:'USER'},
                { title: 'Abrir Canal Externo', icon: 'mdi-access-point', action:'EXTERNO'},
                { title: 'Tocar Mensagem', icon: 'mdi-play-speed', sub:''},
            ],
            menuHelper: [
                { title: 'Abrir Canal Usuário', icon: 'mdi-phone-in-talk', action:'USER'},
                { title: 'Abrir Canal Externo', icon: 'mdi-access-point', action:'EXTERNO'},
                { title: 'Agenda', icon: 'mdi-calendar-month', action:'AGENDA'},
                { title: 'Tocar Mensagem', icon: 'mdi-play-speed', action:'AUDIO'},
               { title: 'Gerenciar Áudios', icon: 'mdi-library-music', action:'AUDIO_FILES'},
                { title: 'Status', icon: 'mdi-heart-pulse', action:'STATUS'},
                { title: 'Exemplo sub-menu', icon: 'mdi-menu', sub:[
                    {title: 'sub-menu 1', icon: 'mdi-menu', action:'SUBMENU1'},
                    {title: 'sub-menu 2', icon: 'mdi-menu', action:'SUBMENU2'}]
                },
            ],
            menuMissao: [
                { title: 'Visualizar Missão', icon: 'mdi-shield-airplane', action:'VER_MISSAO'},
                { title: 'Cancelar Missão', icon: 'mdi-cancel', action:'CANCEL_MISSAO'},
            ],
            helperSelecionado: '',
        }),
        watch:{
            menuAudio(val, oldVal) {
                this.menuAgente[2].sub = val
            },
            carregando(val) {
                if (!val) this.getHelpers()
            }
        },
        mounted(){
            this.axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(sessionStorage.getItem('user')).token;
            this.permissao = JSON.parse(sessionStorage.user).user.permissao
            // arcgis = new Arcgis(-50.67,39.55) // set locate
            arcgis = new Arcgis()
            arcgis.loader()
            bus.$on('rightClickMap', (args) => this.clicou(args))
            bus.$on('carregouMap', (args) => this.carregando = !args)
            bus.$on('LIST_AUDIO', args => {
                this.menuAudio = []
                for (let audio of args.dados.files) {
                    this.menuAudio.push({title: audio, icon: 'mdi-play', action: audio})
                }
            })
        },
        beforeDestroy(){
            bus.$off('rightClickMap')
            bus.$off('carregouMap')
            bus.$off('LIST_AUDIO')
            //arcgis.removeListeners();
            arcgis.clearEvents()
        },
        methods: {
            clicou(args) {
                this.mapArgs = args;
                this.helperSelecionado = '';
                this.x = args.screenPoint.x
                this.y = args.screenPoint.y
                this.latitude = args.results[0].mapPoint.latitude;
                this.longitude = args.results[0].mapPoint.longitude;

                if(args.results[1]) {
                    switch (args.results[0].graphic.layer.title) {
                        case 'Helpers':
                            //let ip = args.results[0].graphic.attributes.ip_helper
                            this.helperSelecionado = args.results[0].graphic;
                            this.titulo = this.helperSelecionado.attributes.nome;
                            if(this.permissao ==='Operador') this.items = this.menuAgente;
                            else this.items = this.menuHelper;
                            let helper = this.selectHelper(this.helperSelecionado.attributes.nome)
                            let cliente = `${this.helperSelecionado.attributes.ip_helper}@${JSON.parse(sessionStorage.user).user.setor}`
                            this.cliente = cliente
                            this.$socket.emit('MSG_HELPER', {
                                cliente,
                                //cliente:`'192.169.200.211'@${JSON.parse(sessionStorage.user).user.setor}`,
                                requisicao:'LIST_AUDIO',
                                dados:'',
                                status:0
                            })
                            break;

                        case 'Missões':
                            this.titulo = "MISSÃO";
                            this.items = this.menuMissao;
                            break;

                        default:
                            break;
                    }
                }
                else {
                    this.titulo ='Helper';
                    this.items = this.menuMapa;
                }

                // $nextTick chama uma função callback que espera a atualização da DOM
                this.showMenu = false;
                this.$nextTick(() => {
                    this.showMenu = true
                })
            },
            acaoMenu(item) {
                switch (item.action) {
                    case 'USER':
                        this.$router.push('/map/atendimento/helper/'+this.helperSelecionado.attributes.nome+'/user')
                            .catch(()=>{})
                        break

                    case 'EXTERNO':
                        this.$router.push('/map/atendimento/helper/'+this.helperSelecionado.attributes.nome+'/externo')
                            .catch(()=>{})
                        break

                    case 'MISSAO_DRONE':
                        this.lateralWidth = '400px';
                        bus.$emit('NEW_MISSION', this.mapArgs);
                        this.$router.push({
                            path:'/map/missao',
                            query: {
                                nome_drone: "000000001",
                                altitude: 40.0,
                                velocidade: 5.0,
                                latitude: this.latitude,
                                longitude: this.longitude,
                            }
                        }).catch(()=>{})
                        break;

                    case 'CAMERAS':
                        break;

                    case 'AUDIO':
                        this.$router.push('/map/audio/helper/'+this.helperSelecionado.attributes.nome).catch(()=>{})
                        break;

                    case 'AUDIO_FILES':
                        this.$router.push('/audio/helper/'+this.helperSelecionado.attributes.nome).catch(()=>{})
                        break;

                    case 'AGENDA':
                        this.$router.push('/agenda/helper/'+this.helperSelecionado.attributes.nome);
                        this.$router.push('/agenda/helper/'+this.helperSelecionado.attributes.nome).catch(()=>{})
                        break;

                    case 'STATUS':
                        this.$router.push('/helpers').catch(()=>{})
                        break;

                    default:
                        if(item.action.search(/mp4|ogg|aac|mp3|wav/gi) !== -1)
                            this.$router.push('/map/atendimento/helper/'+this.helperSelecionado.attributes.nome+'/msg/'+item.action)
                            this.$socket.emit('MSG_HELPER', {
                                cliente: this.cliente,
                                requisicao: 'PLAY_AUDIO',
                                dados: item.action,
                                status:0
                            })
                        //alert('sem ação configurada ' + item.action);
                        break;
                }
            },
            getHelpers() {
                let api = '/helper';
                this.axios.get(api)
                    .then(response => {
                        let temp = [...response.data]
                        if(JSON.parse(sessionStorage.user).user.permissao.search(/super/gi) === -1) {
                            this.listaHelpers = temp.filter(el => {
                                    return el.properties.setor === JSON.parse(sessionStorage.user).user.setor
                                })
                        }
                        else this.listaHelpers = [...response.data]

                        //console.log("lista", this.listaHelpers)
                        

                        for (let i in this.listaHelpers) {
                            this.listaHelpers[i].text = this.listaHelpers[i].nomeHelper
                        }
                        arcgis.loadGraphicArray(this.listaHelpers, "Helpers")
                    }).catch(err=>{})
            },
            selectHelper(nome){
                return this.listaHelpers.filter(u => u.properties.nome === nome)[0]
            }
        }
    }
</script>

<style scoped>
    /* @import url('https://js.arcgis.com/4.13/esri/css/main.css'); */
    body, html, #viewDiv {
        padding: 0;
        margin: 0;
        height: 100%;
        width: 100%;
    }
</style>
